@font-face {
  font-family: 'Kaushan Script';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.eot');
  src: url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.eot?#iefix') format('embedded-opentype'),
       local('Kaushan Script'),
       local('Kaushan-Script-regular'),
       url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.woff2') format('woff2'),
       url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.woff') format('woff'),
       url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.ttf') format('truetype'),
       url('/fonts/Kaushan-Script-regular/Kaushan-Script-regular.svg#KaushanScript') format('svg');
}

@font-face {
  font-family: 'Droid Serif';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Droid-Serif-regular/Droid-Serif-regular.eot');
  src: url('/fonts/Droid-Serif-regular/Droid-Serif-regular.eot?#iefix') format('embedded-opentype'),
       local('Droid Serif'),
       local('Droid-Serif-regular'),
       url('/fonts/Droid-Serif-regular/Droid-Serif-regular.woff2') format('woff2'),
       url('/fonts/Droid-Serif-regular/Droid-Serif-regular.woff') format('woff'),
       url('/fonts/Droid-Serif-regular/Droid-Serif-regular.ttf') format('truetype'),
       url('/fonts/Droid-Serif-regular/Droid-Serif-regular.svg#DroidSerif') format('svg');
}

@font-face {
  font-family: 'Droid Serif';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Droid-Serif-700/Droid-Serif-700.eot');
  src: url('/fonts/Droid-Serif-700/Droid-Serif-700.eot?#iefix') format('embedded-opentype'),
       local('Droid Serif Bold'),
       local('Droid-Serif-700'),
       url('/fonts/Droid-Serif-700/Droid-Serif-700.woff2') format('woff2'),
       url('/fonts/Droid-Serif-700/Droid-Serif-700.woff') format('woff'),
       url('/fonts/Droid-Serif-700/Droid-Serif-700.ttf') format('truetype'),
       url('/fonts/Droid-Serif-700/Droid-Serif-700.svg#DroidSerif') format('svg');
}

@font-face {
  font-family: 'Droid Serif';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/Droid-Serif-italic/Droid-Serif-italic.eot');
  src: url('/fonts/Droid-Serif-italic/Droid-Serif-italic.eot?#iefix') format('embedded-opentype'),
       local('Droid Serif Italic'),
       local('Droid-Serif-italic'),
       url('/fonts/Droid-Serif-italic/Droid-Serif-italic.woff2') format('woff2'),
       url('/fonts/Droid-Serif-italic/Droid-Serif-italic.woff') format('woff'),
       url('/fonts/Droid-Serif-italic/Droid-Serif-italic.ttf') format('truetype'),
       url('/fonts/Droid-Serif-italic/Droid-Serif-italic.svg#DroidSerif') format('svg');
}

@font-face {
  font-family: 'Droid Serif';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.eot');
  src: url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.eot?#iefix') format('embedded-opentype'),
       local('Droid Serif Bold Italic'),
       local('Droid-Serif-700italic'),
       url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.woff2') format('woff2'),
       url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.woff') format('woff'),
       url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.ttf') format('truetype'),
       url('/fonts/Droid-Serif-700italic/Droid-Serif-700italic.svg#DroidSerif') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Montserrat-regular/Montserrat-regular.eot');
  src: url('/fonts/Montserrat-regular/Montserrat-regular.eot?#iefix') format('embedded-opentype'),
       local('Montserrat-Regular'),
       local('Montserrat-regular'),
       url('/fonts/Montserrat-regular/Montserrat-regular.woff2') format('woff2'),
       url('/fonts/Montserrat-regular/Montserrat-regular.woff') format('woff'),
       url('/fonts/Montserrat-regular/Montserrat-regular.ttf') format('truetype'),
       url('/fonts/Montserrat-regular/Montserrat-regular.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Montserrat-700/Montserrat-700.eot');
  src: url('/fonts/Montserrat-700/Montserrat-700.eot?#iefix') format('embedded-opentype'),
       local('Montserrat-Bold'),
       local('Montserrat-700'),
       url('/fonts/Montserrat-700/Montserrat-700.woff2') format('woff2'),
       url('/fonts/Montserrat-700/Montserrat-700.woff') format('woff'),
       url('/fonts/Montserrat-700/Montserrat-700.ttf') format('truetype'),
       url('/fonts/Montserrat-700/Montserrat-700.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  src: url('/fonts/Montserrat-Light/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
       local('Montserrat-Light'),
       local('Montserrat-Light'),
       url('/fonts/Montserrat-Light/Montserrat-Light.woff2') format('woff2'),
       url('/fonts/Montserrat-Light/Montserrat-Light.woff') format('woff'),
       url('/fonts/Montserrat-Light/Montserrat-Light.ttf') format('truetype'),
       url('/fonts/Montserrat-Light/Montserrat-Light.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 100;
  font-style: normal;
  src: url('/fonts/Roboto-Slab-100/Roboto-Slab-100.eot');
  src: url('/fonts/Roboto-Slab-100/Roboto-Slab-100.eot?#iefix') format('embedded-opentype'),
       local('Roboto Slab Thin'),
       local('Roboto-Slab-100'),
       url('/fonts/Roboto-Slab-100/Roboto-Slab-100.woff2') format('woff2'),
       url('/fonts/Roboto-Slab-100/Roboto-Slab-100.woff') format('woff'),
       url('/fonts/Roboto-Slab-100/Roboto-Slab-100.ttf') format('truetype'),
       url('/fonts/Roboto-Slab-100/Roboto-Slab-100.svg#RobotoSlab') format('svg');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/Roboto-Slab-300/Roboto-Slab-300.eot');
  src: url('/fonts/Roboto-Slab-300/Roboto-Slab-300.eot?#iefix') format('embedded-opentype'),
       local('Roboto Slab Light'),
       local('Roboto-Slab-300'),
       url('/fonts/Roboto-Slab-300/Roboto-Slab-300.woff2') format('woff2'),
       url('/fonts/Roboto-Slab-300/Roboto-Slab-300.woff') format('woff'),
       url('/fonts/Roboto-Slab-300/Roboto-Slab-300.ttf') format('truetype'),
       url('/fonts/Roboto-Slab-300/Roboto-Slab-300.svg#RobotoSlab') format('svg');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.eot');
  src: url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.eot?#iefix') format('embedded-opentype'),
       local('Roboto Slab Regular'),
       local('Roboto-Slab-regular'),
       url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.woff2') format('woff2'),
       url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.woff') format('woff'),
       url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.ttf') format('truetype'),
       url('/fonts/Roboto-Slab-regular/Roboto-Slab-regular.svg#RobotoSlab') format('svg');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Roboto-Slab-700/Roboto-Slab-700.eot');
  src: url('/fonts/Roboto-Slab-700/Roboto-Slab-700.eot?#iefix') format('embedded-opentype'),
       local('Roboto Slab Bold'),
       local('Roboto-Slab-700'),
       url('/fonts/Roboto-Slab-700/Roboto-Slab-700.woff2') format('woff2'),
       url('/fonts/Roboto-Slab-700/Roboto-Slab-700.woff') format('woff'),
       url('/fonts/Roboto-Slab-700/Roboto-Slab-700.ttf') format('truetype'),
       url('/fonts/Roboto-Slab-700/Roboto-Slab-700.svg#RobotoSlab') format('svg');
}

@font-face {
    font-family: 'Visby-CF-Extra-Bold';
    src: url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.eot');
    src: url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.woff2') format('woff2'),
    url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.woff') format('woff'),
    url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.ttf') format('truetype'),
    url('/fonts/Visby-CF-Extra-Bold/Visby-CF-Extra-Bold.svg#youworkforthem') format('svg');
    font-weight: normal;
    font-style: normal;
}
